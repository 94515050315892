import { api } from '@redux/api/api'
import { TagTypes } from '@redux/api/tagTypes'
import type { CartLegsType } from '@redux/features/cart/types/cartState'
import { checkoutUrls } from '@redux/features/checkout/api'
import type { BookingOrderItemInfoResponse, BookingTotalPriceResponse } from '@redux/features/checkout/types/booking'
import type { IBooking } from '@redux/features/checkout/types/booking'
import type {
  BookingServiceResponseType,
  GetBookingServiceResponseType,
  GetPaidServicesType,
  PaidServices,
  PostBookingServicePayloadType,
} from '@redux/features/checkout/types/service'
import { preparePostBookingData } from '@redux/features/checkout/utils/postBooking'
import { filterTrains } from '@redux/features/checkout/utils/route'

import type { PassengersFieldsType } from '@Types/common/passengers'
import type { ICoachClass } from '@Types/routes/coachClasses'
import type { IRoute } from '@Types/routes/route'
import type { VoucherResponse } from '@Types/routes/route'
import { additionalBaseUrl, baseUrl } from '@constants/api/api'
import { XAPIUserKeyAdditional } from '@constants/api/tokens'
import type { PaymentMethodsName } from '@constants/payment'
import type { PassengersFormType } from '@utils/forms/passengers/types'
import { preparePostPassengersData } from '@utils/passengers/preparePostPassengersPayload'
import getQueryParamsFromUrl from '@utils/url/getQueryParamsFromUrl'

const checkoutApi = api.injectEndpoints({
  endpoints: build => ({
    deleteBooking: build.query<void, string>({
      query: bookingId => ({
        method: 'DELETE',
        url: checkoutUrls.booking(bookingId),
      }),
    }),
    getBookingOrderItemInfo: build.query<BookingOrderItemInfoResponse, string>({
      providesTags: [TagTypes.BOOKING_ORDER_INFO],
      query: bookingId => ({
        method: 'GET',
        url: checkoutUrls.bookingOrderItemInfo(bookingId),
      }),
    }),
    getBookingPaidServiceInfo: build.query<PaidServices[], GetPaidServicesType>({
      query: ({ bookingId, stepId }) => ({
        method: 'GET',
        url: checkoutUrls.bookingPaidServiceInfo(bookingId, stepId),
      }),
      transformResponse: (response: Record<string, PaidServices>) => Object.values(response),
    }),
    getBookingService: build.query<GetBookingServiceResponseType, string>({
      providesTags: [TagTypes.GET_BOOKING_SERVICE],
      query: bookingId => ({
        method: 'GET',
        url: checkoutUrls.bookingService(bookingId),
      }),
    }),
    getBookingTotalPrice: build.query<
      BookingTotalPriceResponse,
      { bookingId: string; paymentMethod: PaymentMethodsName }
    >({
      providesTags: [TagTypes.TOTAL_PRICE],
      query: ({ bookingId, paymentMethod }) => ({
        keepUnusedDataFor: 0,
        method: 'GET',
        url: checkoutUrls.bookingTotalPrice(bookingId, paymentMethod),
      }),
    }),
    getTimetableCoachClasses: build.mutation<ICoachClass[], { searchSessionId: string; trainKey: string }>({
      query: ({ searchSessionId, trainKey }) => ({
        body: { search_session_id: searchSessionId, train_key: trainKey },
        headers: { 'X-API-User-Key': XAPIUserKeyAdditional },
        method: 'POST',
        url: checkoutUrls.timetableCoachClasses,
      }),
    }),

    postBooking: build.mutation<IBooking, { cartLegs: CartLegsType[]; searchSessionId: string }>({
      query: ({ cartLegs, searchSessionId }) => ({
        body: { ...preparePostBookingData(cartLegs), search_session_id: searchSessionId },
        method: 'POST',
        url: checkoutUrls.externalBooking,
      }),
    }),

    postBookingService: build.mutation<
      BookingServiceResponseType,
      { bookingId: string; payload: PostBookingServicePayloadType }
    >({
      invalidatesTags: [TagTypes.TOTAL_PRICE, TagTypes.BOOKING_ORDER_INFO, TagTypes.GET_BOOKING_SERVICE],
      query: ({ bookingId, payload }) => ({
        body: payload,
        method: 'POST',
        url: checkoutUrls.bookingService(bookingId),
      }),
    }),
    postPassengers: build.mutation<
      void,
      { bookingId: string; passengersForm: PassengersFormType; requiredField: PassengersFieldsType[] }
    >({
      query: ({ bookingId, passengersForm, requiredField }) => ({
        body: preparePostPassengersData({ passengersForm, requiredField }),
        method: 'POST',
        url: checkoutUrls.passengers(bookingId),
      }),
    }),
    postPreBooking: build.mutation<IPrebooking, string>({
      query: bookingId => ({
        body: {},
        method: 'POST',
        url: checkoutUrls.preBooking(bookingId),
      }),
    }),
    postSearch: build.mutation<
      IRoute,
      {
        arrivalDatetime?: string
        formData: IBookingPayload
        needSelectFirstTrains?: boolean
        queryParams?: string
        sessionId?: string
      }
    >({
      query: ({ formData, queryParams, sessionId }) => {
        return {
          body: formData,
          headers: { 'X-API-User-Key': XAPIUserKeyAdditional },
          method: 'POST',
          params: {
            ...(queryParams && additionalBaseUrl !== baseUrl ? getQueryParamsFromUrl() : {}),
            frontendSearchRetry: 1,
            session_id: sessionId,
          },
          url: checkoutUrls.search,
        }
      },
      transformResponse: (response: IRoute, _, { arrivalDatetime }) => {
        return {
          ...response,
          trains: filterTrains({ arrivalDatetime, trains: response.trains }),
        }
      },
    }),
    postSearchBatch: build.mutation<
      IRoute,
      { arrivalDatetime?: string; needSelectFirstTrains?: boolean; searchSessionId: string }
    >({
      query: ({ searchSessionId }) => ({
        body: { search_session_id: searchSessionId },
        headers: { 'X-API-User-Key': XAPIUserKeyAdditional },
        method: 'POST',
        url: checkoutUrls.searchBatch,
      }),
      transformResponse: (response: IRoute, _, { arrivalDatetime }) => {
        return {
          ...response,
          trains: filterTrains({ arrivalDatetime, trains: response.trains }),
        }
      },
    }),
    postVoucher: build.mutation<VoucherResponse, { bookingId: string; voucher: string }>({
      invalidatesTags: [TagTypes.TOTAL_PRICE, TagTypes.BOOKING_ORDER_INFO],
      query: ({ bookingId, voucher }) => ({
        body: { voucher_code: voucher },
        method: 'POST',
        url: checkoutUrls.voucher(bookingId),
      }),
    }),
    setBookingCurrency: build.mutation<{ total_price: IPrice }, string>({
      query: bookingId => ({
        body: {},
        method: 'POST',
        url: checkoutUrls.bookingCurrency(bookingId),
      }),
    }),
  }),
})

export const {
  endpoints: checkoutEndpoints,
  useGetBookingOrderItemInfoQuery,
  useGetBookingPaidServiceInfoQuery,
  useGetBookingServiceQuery,
  useGetBookingTotalPriceQuery,
  useGetTimetableCoachClassesMutation,
  usePostBookingMutation,
  usePostBookingServiceMutation,
  usePostPassengersMutation,
  usePostPreBookingMutation,
  usePostSearchBatchMutation,
  usePostSearchMutation,
  usePostVoucherMutation,
} = checkoutApi
